/* General Styles */
body {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f7fb;
}

/* Prescription Container */
.prescription-container {
    width: 80%;
    max-width: 1100px;
    margin: 40px auto;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
    font-size: 1.1rem;
}

/* Header Section */
.header {
    text-align: center;
    margin-bottom: 40px;
    border-bottom: 3px solid #007bff;
    padding-bottom: 15px;
}

.header h1 {
    font-size: 3em;
    color: #003366;
    margin: 0;
    font-weight: 700;
    letter-spacing: 1px;
}

.header .timestamp {
    font-size: 1.2em;
    color: #6c757d;
}

/* Section Title */
.section-title {
    text-align: center;
    color: #003366;
    font-size: 2.5em;
    font-weight: 600;
    margin-bottom: 20px;
}

/* Prescription Details Table */
.prescription-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.prescription-table th, .prescription-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 1.1em;
}

.prescription-table th {
    background-color: #007bff;
    color: white;
}

.prescription-table td {
    background-color: #f9f9f9;
}

/* Signature Section */
.signature-section {
    margin-top: 40px;
    padding-top: 25px;
    border-top: 3px solid #007bff;
    text-align: center;
}

.signature-section .signature-line {
    width: 60%;
    margin: 20px auto;
    border-bottom: 3px solid #007bff;
    padding-bottom: 10px;
}

.signature-date {
    font-size: 1.2em;
    color: #6c757d;
}

/* Buttons */
.print-button-container {
    text-align: center;
    margin-top: 40px;
}

.print-button {
    padding: 15px 30px;
    background-color: #003366;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.3em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

.print-button:hover {
    background-color: #00509e;
}

/* Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.2);
    text-align: center;
}

.modal-content h2 {
    color: #ff0000;
    font-weight: 700;
    font-size: 1.8em;
}

.modal-content p {
    font-size: 1.2em;
    color: #6c757d;
}

.modal-close-button {
    padding: 12px 24px;
    background-color: #003366;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.modal-close-button:hover {
    background-color: #00509e;
}

/* Print-Specific Styles */
@media print {
    /* Hide everything */
    body * {
        visibility: hidden;
    }

    /* Only show the prescription container and its children */
    .prescription-container, .prescription-container * {
        visibility: visible;
    }

    /* Position the prescription container to take the full page */
    .prescription-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    /* Hide modal and print button during printing */
    .modal-overlay, .print-button-container {
        display: none;
    }
}

/* Signature Section */
.signature-section {
    margin-top: 40px;
    padding-top: 25px;
    border-top: 3px solid #007bff;
    text-align: center;
}

.signature-section .signature-line {
    width: 60%;
    margin: 20px auto;
    border-bottom: 3px solid #007bff;
    padding-bottom: 10px;
}

.signature-date {
    font-size: 1.2em;
    color: #6c757d;
}

/* Watermark */
.watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5em;
    color: rgba(0, 123, 255, 0.1); /* Light blue color */
    font-weight: bold;
    pointer-events: none;
    user-select: none;
    z-index: 1000; /* High z-index to make sure it's on top */
    text-transform: uppercase;
    opacity: 0.5; /* Ensure it's visible but doesn't overpower */
    display: block; /* Ensure it's visible */
    text-align: center;
}

/* Print Button */
.print-button-container {
    text-align: center;
    margin-top: 40px;
}

.print-button {
    padding: 15px 30px;
    background-color: #003366;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.3em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

.print-button:hover {
    background-color: #00509e;
}