/* CompanyAccountsTab.css */

.company-tab-wrapper {
  padding: 2rem;
  background-color: white;
  font-family: "Segoe UI", sans-serif;
  margin-top: 5%;
}

.company-filter-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.company-date-input label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: #333;
}

.company-date-input input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.company-report {
  position: relative;
  padding: 2rem 2rem 2rem 2.5rem;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.company-report::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 100%;
  background: linear-gradient(to bottom, #4285F4, #34a853);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.company-report:hover {
  box-shadow: 0 12px 30px rgba(66, 133, 244, 0.15);
}

.company-title {
  text-align: center;
  font-size: 2.2rem;
  font-weight: 700;
  color: #4285F4;
  margin-bottom: 0.2rem;
}

.company-tagline {
  text-align: center;
  font-style: italic;
  color: #555;
  margin-bottom: 2rem;
}

.section-heading {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #333;
}

.report-period {
  margin-bottom: 1rem;
  font-weight: 500;
}

.summary-block {
  margin-bottom: 2rem;
}

.sub-heading {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #1f2937;
}

.summary-list {
  list-style: disc;
  padding-left: 1.25rem;
  color: #444;
}

.doctor-table-wrapper {
  overflow-x: auto;
}

/* 🔧 Updated table to avoid content cutting */
.doctor-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  font-size: 0.85rem;
  table-layout: fixed;
  word-wrap: break-word;
}

.doctor-table th,
.doctor-table td {
  padding: 6px;
  border: 1px solid #ccc;
  text-align: center;
  word-break: break-word;
  overflow-wrap: break-word;
}

/* 📏 Improved column widths to stretch table more */
.doctor-table th:nth-child(1),
.doctor-table td:nth-child(1)
{
  width: 15%;
}

.doctor-table th:nth-child(2),
.doctor-table td:nth-child(2)
{
  width: 17%;
}

.doctor-table th:nth-child(3),
.doctor-table td:nth-child(3) {
  width: 15%;
}

.doctor-table th:nth-child(4),
.doctor-table td:nth-child(4) {
  width: 15%;
}

.doctor-table th:nth-child(5),
.doctor-table td:nth-child(5) {
  width: 15%;
}

.doctor-table th:nth-child(6),
.doctor-table td:nth-child(6) {
  width: 15%;
}

.doctor-table th:nth-child(7),
.doctor-table td:nth-child(7) {
  width: 15%;
}

/* 💰 Right-align currency columns for better readability */
.doctor-table td:nth-child(5),
.doctor-table td:nth-child(6),
.doctor-table td:nth-child(7) 
{
  text-align: right;
}

.doctor-table th {
  background-color: #e2e8f0;
  font-weight: 600;
  color: #333;
}

.report-footer {
  margin-top: 2rem;
  font-size: 0.875rem;
  text-align: center;
  color: #888;
}

.print-btn-wrapper {
  text-align: right;
  margin-top: 1.5rem;
}

.btn {
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.filter-btn {
  background-color: #4285F4;
  color: #fff;
}

.filter-btn:hover {
  background-color: #005ef6;
}

.print-btn {
  background-color: #16a34a;
  color: white;
}

.print-btn:hover {
  background-color: #15803d;
}

/* Print-specific tuning if ever needed for physical printouts */
@media print {
  .company-tab-wrapper {
    margin: 0;
    padding: 0;
  }

  .doctor-table {
    font-size: 12px;
    table-layout: fixed;
    width: 100%;
    word-wrap: break-word;
  }

  .doctor-table th,
  .doctor-table td {
    padding: 4px;
    word-break: break-word;
  }

  .doctor-table-wrapper {
    overflow: visible !important;
  }
}