/* === Base Styles === */
.contact-page {
  font-family: 'Segoe UI', sans-serif;
  background-color: white;
  padding-bottom: 4rem;
  color: #333;
  margin-top: 5%;
}

.contact-header {
  background-color: #4285F4;
  color: white;
  padding: 3rem 2rem;
  border-radius: 0 0 50% 50% / 10%;
  text-align: center;
}

.contact-header h1 {
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

.contact-header p {
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 25px;
}

#box:hover{
  color: #4285F4;
}
.contact-card {
  background-color: white;
  margin: -3rem auto 3rem;
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 1100px;
  text-align: center;
}

.contact-card h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.highlight {
  color:#4285F4;
}

.contact-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem;
}

.contact-box {
  flex: 1 1 300px;
  max-width: 350px;
  background-color: #fdfdfd;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  border: 2px;
}

.icon {
  color: #4a5e37;
  margin-bottom: 0.5rem;
}

.contact-box h4 {
  color:#4285F4;
  margin-bottom: 1rem;
}

.contact-detail {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.subtext {
  color: #888;
  font-size: 0.9rem;
}

.contact-form-section {
  max-width: 1100px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.form-content {
  margin-bottom: 1.5rem;
  text-align: left;
}

.form-content h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.form-content p {
  font-size: 1.1rem;
  color: #555;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form input,
.contact-form textarea {
  padding: 0.8rem 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
}

.phone-input {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.country-code {
  width: 60px;
  text-align: center;
  background-color: #f2f2f2;
  font-weight: bold;
}

.contact-form button {
  background-color:#4285F4;
  color: white;
  padding: 0.9rem;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  width: 150px;
  align-self: flex-start;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #3370d3;
}

/* === Responsive Design === */
@media (max-width: 1024px) {
  .contact-header h1 {
    font-size: 2.2rem;
  }

  .contact-card h2,
  .form-content h2 {
    font-size: 1.7rem;
  }

  .contact-options {
    justify-content: center;
  }

  .contact-form-section {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .contact-header {
    padding: 2rem 1rem;
  }

  .contact-header h1 {
    font-size: 1.8rem;
  }

  .contact-header p {
    font-size: 1rem;
  }

  .contact-card {
    padding: 2rem 1.2rem;
    margin: -2rem auto 2rem;
  }

  .contact-options {
    flex-direction: column;
    align-items: center;
  }

  .contact-box {
    width: 90%;
  }

  .form-content h2 {
    font-size: 1.5rem;
  }

  .contact-form button {
    width: 100%;
    align-self: center;
  }

  .phone-input {
    flex-direction: column;
  }

  .country-code {
    width: 100%;
  }
}
