.how-to-use-container {
  padding: 50px 20px;
  background-color: #FFFFFF;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

/* Title styling */
.how-to-use-title {
  font-size: 2.5rem;
  color: #1A2A44;
  margin-bottom: 40px;
  animation: fadeIn 1s ease-in-out;
}

/* Subtitle styling */
.how-to-use-subtitle {
  font-size: 1.2rem;
  color: #6B7280;
  margin-bottom: 90px;
  animation: fadeIn 1.2s ease-in-out;

}


/* Steps container */
.steps-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
  max-width: 1200px;
  /* height: 200px; */
  margin: 0 auto;
}

/* Individual step card */
.step-card {
  background-color: #E6F0FA;
  border-radius: 10px;
  padding: 20px;
  width: 200px;
  height: 200px;
  text-align: center;
  animation: slideUp 1s ease-in-out;
  animation-fill-mode: backwards;
}

/* Step image */
.step-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 15px;
  border-radius: 10px;
}

/* Step title */
.step-title {
  font-size: 1.3rem;
  color: #4285F4;
  margin-bottom: 40px;
}

/* Step description */
.step-description {
  font-size: 0.9rem;
  color: #6B7280;
}


/* Animation for fade-in */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation for slide-up effect on cards */
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Staggered animation delays for each step */
.step-card:nth-child(1) {
  animation-delay: 0.2s;
}
.step-card:nth-child(2) {
  animation-delay: 0.4s;
}
.step-card:nth-child(3) {
  animation-delay: 0.6s;
}
.step-card:nth-child(4) {
  animation-delay: 0.8s;
}
.step-card:nth-child(5) {
  animation-delay: 1s;
}

/* Responsive design */
@media (max-width: 768px) {
  .steps-container {
    flex-direction: column;
    align-items: center;
  }

  .step-card {
    width: 80%;
    margin-bottom: 20px;
  }

  .step-card:not(:last-child)::after {
    display: none;
  }

  .how-to-use-title {
    font-size: 2rem;
  }

  .how-to-use-subtitle {
    font-size: 1rem;
  }
}

/* Styles for Step 5 with background image and hover effect */
.step-card-with-bg {
  position: relative;
  background-image: url('./therapy.jpg');
  background-size: cover;
  background-position: center;
  transition: opacity 0.3s ease;
}

/* Ensure the content inside Step 5 is readable */
.step-card-with-bg .step-image,
.step-card-with-bg .step-title,
.step-card-with-bg .step-description {
  position: relative;
  z-index: 1;
}

/* Hover effect: Fade the background image */
.step-card-with-bg:hover {
  opacity: 0.9;
}

/* Hover text styling */
.step-card-with-bg .hover-text {
  position:absolute;
  top: 0; /* Cover the entire card from the top */
  left: 0;
  width: 100%;
  height: 100%; /* Take up the full height of the card */
  
  background-color: #E6F0FA; /* Dark blue semi-transparent background */
  color: #FFFFFF;
  font-size: 1rem; /* Slightly larger font for better readability */
  display: flex; /* Center the text */
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px; /* Add padding for better spacing */
  transform: translateY(100%); /* Initially hidden below the card */
  opacity: 0; /* Completely invisible initially */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition for both properties */
  z-index: 2;
  border-radius: 10px; /* Match the card's border radius */
}

/* Slide the text up and make it visible on hover */
.step-card-with-bg:hover .hover-text {
  transform: translateY(0); /* Slide up to cover the card */
  opacity: 0.95; /* Make the text fully visible */
}

.hover-text{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.step1{
  position: relative;
  background-image: url('signup6.jpg');
  background-size: cover;
  background-position: center;
  transition: opacity 0.3s ease;
}

.step2{
  position: relative;
  background-image: url('assesment.jpg');
  background-size: cover;
  background-position: center;
  transition: opacity 0.3s ease;
}

.step3{
  position: relative;
  background-image: url('appointment6.jpg');
  background-size: cover;
  background-position: center;
  transition: opacity 0.3s ease;
}