/* AddOperator.css */

.add-operator-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    background: linear-gradient(135deg, #ffffff 60%, #f0f4f8 100%);
  }
  
  .add-operator-card {
    background-color: rgba(255, 255, 255, 0.235);
    backdrop-filter: blur(10px);
    padding: 2.5rem;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 450px;
    border: 1px solid #4285F4; /* optional */
    box-shadow: 0 8px 25px rgba(66, 133, 244, 0.15); /* blue glow */
  }
  
  .add-operator-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #4285F4;
    font-weight: 600;
  }
  
  .add-operator-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .add-operator-form input {
    padding: 10px 14px;
    border: 1px solid #cbd5e1;
    border-radius: 6px;
    font-size: 1rem;
    transition: border 0.3s ease;
  }
  
  .add-operator-form input:focus {
    outline: none;
    border-color: #d03c3c;
  }
  
  .add-operator-form button {
    padding: 12px;
    background-color: #4285F4;
    color: #fff;
    font-weight: bold;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .add-operator-form button:hover {
    background-color: #0050d0;
  }