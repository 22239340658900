/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
    color: #333;
}

a {
    text-decoration: none;
    color: inherit;
}

/* General Containers */
.pageStyle {
    padding: 20px;
    margin-top: 70px;
    background-color: #ffffff;
}

/* Layout Styles */
.mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin: 1% 0 50px;
}

.leftBoxes, .rightBoxes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 70%;
    padding: 7px;
}

/* Box Styles */
.boxStyle {
    background: linear-gradient(135deg, #FF8096, #FF4B75);
    color: white;
    padding: 40px;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    border: 5px solid #FF8096;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease, transform 1s ease;
}

.boxStyle.slideIn {
    transform: translateX(0);
    opacity: 1;
}

/* Animations */
.animate {
    opacity: 1;
    transform: translateY(0);
}

/* Centered Content */
.centerContent {
    text-align: center;
    width: 50%;
}

.titleStyle {
    font-size: 3rem;
    font-weight: 700;
    color: #FF8096;
    margin-bottom: 10px;
}

.subtitleStyle {
    font-size: 1.2rem;
    color: #FF8096;
    margin-bottom: 20px;
}

/* Image Styles */
.imageOverlay {
    width: 300px;
    height: 300px;
    background-size: cover;
    background-position: center;
    border-radius: 30%;
    margin: 0 auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.imageStyle {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.stripImageStyle {
    width: 15%;
    height: 15%;
    margin-left: 9%;
    border-radius: 100%;
    display: block;
}

/* Sections for Scrolling */
.sectionStyle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
    border-bottom: 1px solid #eaeaea;
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 0.8s ease, transform 0.8s ease;
}

.visibleStyle {
    opacity: 1;
    transform: translateX(0);
}

.textContainerStyle {
    flex: 1;
    padding: 20px;
}

.imageContainerStyle {
    flex: 1;
    text-align: center;
}

/* Query Button */
.queryButton {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: #FF4B75;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: transform 0.2s ease;
}

.queryButton:hover {
    transform: scale(1.1);
}

/* Modal Styles */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    z-index: 1000;
}

.modalContent {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
}

.modalTitle {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.formField {
    margin-bottom: 20px;
}

.formField label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
    color: #555;
}

.formField input, .formField textarea {
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background: #f9f9f9;
    transition: border 0.3s ease;
}

.formField input:focus, .formField textarea:focus {
    border-color: #FF4B75;
    outline: none;
}

.submitButton {
    background: #FF4B75;
    color: white;
    padding: 12px 20px;
    font-size: 1.1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background: #FF8096;
}

.submitButton:disabled {
    background: #d3d3d3;
    cursor: not-allowed;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .mainContainer {
        flex-direction: column;
        align-items: center;
    }

    .leftBoxes, .rightBoxes {
        width: 100%;
    }

    .centerContent {
        width: 100%;
        margin-bottom: 20px;
    }

    .sectionStyle {
        flex-direction: column;
    }
}

.modalExitMessage {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 1rem;
    text-align: center;
}