/* Slide4.css */
.Con {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #FFFFFF;
  padding: 50px 10%;
  box-sizing: border-box;
  overflow: hidden;
  /* margin-bottom: 10px; */
}

.tagline-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  animation: slideUp 1s ease-in-out;
}

.tagline-section span {
  font-size: 2.5rem;
  font-weight: bold;
  color: #1A3A8C;
}

.underline {
  width: 100px;
  height: 5px;
  left: 500px;
  background-color: #F28C38;
  margin-top: 15px;
}

.content-section {
  display: flex;
  flex-direction: row; /* Stack white boxes vertically */
  align-items: center;
  width: 100%;
  max-width: 1200px;
  gap: 30px; /* Reduced gap between white boxes */
}

.one:hover{
  transform: translateY(-10px);
  cursor: pointer;
  box-shadow: 0 0 15px #4285F4;
}
.two:hover{
  transform: translateY(-10px);
  cursor: pointer;
  box-shadow: 0 0 15px #4285F4;
}

.white-box {
  background-color: rgba(234, 224, 224, 0.2);
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px; /* Limit the width of the white boxes */
  transition: transform 0.3s ease;
}

.image-column {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;
  justify-content: center; /* Center images within the white box */
}

.counsellor-image {
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  opacity: 0;
}

.content-section.is-visible .counsellor-image {
  opacity: 1;
  animation: slideInLeft 0.8s ease-in-out forwards;
}

.content-section.is-visible .counsellor-image.top-left { animation-delay: 0.2s; }
.content-section.is-visible .counsellor-image.bottom-left { animation-delay: 0.4s; }
.content-section.is-visible .counsellor-image.large { animation-delay: 0.6s; }
.content-section.is-visible .counsellor-image.top-right { animation-delay: 0.8s; }
.content-section.is-visible .counsellor-image.bottom-right { animation-delay: 1s; }

.counsellor-image.small {
  width: 120px;
  height: 120px;
}

.counsellor-image.large {
  width: 250px;
  height: 300px;
  position: relative;
}

.top-left { position: absolute; top: 0; left: 0; }
.bottom-left { position: absolute; bottom: 0; left: 0; }
.top-right { position: absolute; top: 0; right: 0; }
.bottom-right { position: absolute; bottom: 0; right: 0; }

.counsellor-info {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
}

.counsellor-info h3 {
  font-size: 1.2rem;
  color: #4285F4;
  margin: 0;
}

.counsellor-info p {
  font-size: 0.9rem;
  color: #1A3A8C;
  margin: 5px 0 0;
}

.text-column {
  opacity: 0;
  text-align: center; /* Center text within the white box */
}

.content-section.is-visible .text-column {
  opacity: 1;
  animation: fadeInRight 1s ease-in-out forwards;
  animation-delay: 0.2s;
}

.headline {
  font-size: 2.5rem;
  font-weight: bold;
  color: #4285F4;
  margin: 0 0 20px;
}

.description {
  font-size: 1.1rem;
  color: #1A3A8C;
  line-height: 1.6;
  margin-bottom: 20px;
}

.session-types {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  justify-content: center; /* Center session types */
}

.session-type {
  display: flex;
  align-items: center;
  gap: 10px;
}

.session-icon {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.session-type span {
  font-size: 1rem;
  color: #1A3A8C;
}

.language-support, .privacy-info, .support-info {
  font-size: 1rem;
  color: #1A3A8C;
  margin: 10px 0;
}

.view-counsellors-btn {
  background-color: #F28C38;
  color: #FFFFFF;
  padding: 10px 30px;
  border: none;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.view-counsellors-btn:hover {
  transform: scale(1.1);
  background-color: #E07B30;
}

.pagination-dots {
  display: flex;
  gap: 10px;
  margin-top: 40px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #D3D3D3;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #4285F4;
}

/* Animations */
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .content-section {
    gap: 20px;
  }

  .white-box {
    max-width: 600px;
  }

  .counsellor-image.large {
    width: 200px;
    height: 250px;
  }

  .counsellor-image.small {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 768px) {
  .con {
    padding: 30px 5%;
  }

  .tagline-section span {
    font-size: 1.5rem;
  }

  .headline {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }

  .session-types {
    flex-direction: column;
    gap: 10px;
  }

  .view-counsellors-btn {
    padding: 8px 25px;
    font-size: 1rem;
  }
}

@media (max-width: 400px) {
  .con {
    padding: 20px 3%;
  }

  .tagline-section span {
    font-size: 1.2rem;
  }

  .headline {
    font-size: 1.8rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .counsellor-image.large {
    width: 150px;
    height: 200px;
  }

  .counsellor-image.small {
    width: 80px;
    height: 80px;
  }

  .counsellor-info h3 {
    font-size: 1rem;
  }

  .counsellor-info p {
    font-size: 0.8rem;
  }

  .language-support, .privacy-info, .support-info {
    font-size: 0.9rem;
  }

  .pagination-dots {
    gap: 8px;
  }

  .dot {
    width: 8px;
    height: 8px;
  }
}