.header-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.header-slider {
  width: 400%; /* 4 pages (2 original + 2 duplicates) */
  height: 100vh;
  display: flex;
  animation: slidePage 16s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95); /* 16s total, custom easing for smoothness */
  position: relative;
}

.header {
  width: 25%; /* Each page takes 1/4 of slider width */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding: 0 10%;
  box-sizing: border-box;
}

.header1 {
  background-image: url('./homeimg.jpg');
  position: relative;
  background-size: cover;
}

.header2 {
  background-image: url('./About.jpg');
  position: relative;
  background-size: cover;
}

.text-box {
  background-color: rgba(234, 224, 224, 0.7);
  padding: 40px;
  border-radius: 15px;
  max-width: 500px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.headline {
  font-size: 3.5rem;
  font-weight: bold;
  color: white;
  margin: 0;
}

.subheadline {
  font-size: 1.2rem;
  color: #1A3A8C;
  margin: 20px 0;
  line-height: 1.6;
}

.cta-button {
  background-color: #4285F4;
  color: #FFFFFF;
  padding: 10px 30px;
  border: none;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.cta-button:hover {
  transform: scale(1.1);
  background-color: #1A3A8C;
}

.pagination-dots {
  display: flex;
  gap: 10px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #D3D3D3;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.dot-1 {
  animation: dotActive1 16s infinite;
}

.dot-2 {
  animation: dotActive2 16s infinite;
}

/* Animations */
@keyframes slidePage {
  0%, 18.75% {
    transform: translateX(0); /* Slide 1: hold 3s */
  }
  25%, 43.75% {
    transform: translateX(-25%); /* Slide 2: transition 1s, hold 3s */
  }
  50%, 68.75% {
    transform: translateX(-50%); /* Slide 1 duplicate: transition 1s, hold 3s */
  }
  75%, 93.75% {
    transform: translateX(-75%); /* Slide 2 duplicate: transition 1s, hold 3s */
  }
  100% {
    transform: translateX(0); /* Back to Slide 1: transition 1s */
  }
}

@keyframes dotActive1 {
  0%, 18.75%, 50%, 68.75% {
    background-color: #4285F4; /* Active for Slide 1 and its duplicate */
  }
  18.76%, 49.99%, 68.76%, 100% {
    background-color: #D3D3D3;
  }
}

@keyframes dotActive2 {
  25%, 43.75%, 75%, 93.75% {
    background-color: #4285F4; /* Active for Slide 2 and its duplicate */
  }
  0%, 24.99%, 43.76%, 74.99%, 93.76%, 100% {
    background-color: #D3D3D3;
  }
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    justify-content: center;
    padding: 5%;
  }

  .text-box {
    max-width: 100%;
    padding: 30px;
  }

  .headline {
    font-size: 2.5rem;
  }

  .subheadline {
    font-size: 1rem;
  }

  .cta-button {
    padding: 8px 25px;
    font-size: 1rem;
  }

  .pagination-dots {
    bottom: 15px;
  }
}

@media (max-width: 400px) {
  .header {
    padding: 3%;
  }

  .text-box {
    padding: 20px;
  }

  .headline {
    font-size: 2rem;
  }

  .subheadline {
    font-size: 0.9rem;
    margin: 15px 0;
  }

  .cta-button {
    padding: 7px 20px;
    font-size: 0.9rem;
  }

  .pagination-dots {
    gap: 8px;
    bottom: 10px;
  }

  .dot {
    width: 8px;
    height: 8px;
  }

  @keyframes slidePage {
    0%, 18.75% {
      transform: translateX(0);
    }
    25%, 43.75% {
      transform: translateX(-25%);
    }
    50%, 68.75% {
      transform: translateX(-50%);
    }
    75%, 93.75% {
      transform: translateX(-75%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes dotActive1 {
    0%, 18.75%, 50%, 68.75% {
      background-color: #4285F4;
    }
    18.76%, 49.99%, 68.76%, 100% {
      background-color: #D3D3D3;
    }
  }

  @keyframes dotActive2 {
    25%, 43.75%, 75%, 93.75% {
      background-color: #4285F4;
    }
    0%, 24.99%, 43.76%, 74.99%, 93.76%, 100% {
      background-color: #D3D3D3;
    }
  }
}