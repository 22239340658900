/* OperatorDetails.css */

.operator-details-wrapper {
    min-height: 100vh;
    background-color: white;
    padding: 4rem 2rem;
    margin-top: 5%;
  }
  
  .operator-title {
    text-align: center;
    font-size: 2.5rem;
    color: #4285F4;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  
  .operator-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .operator-card {
    background: linear-gradient(to bottom right, #ffffff, #f9f9f9);
    padding: 1.75rem;
    border-radius: 12px;
    border-top: 4px solid #4285F4;
    box-shadow: 0 8px 25px rgba(66, 133, 244, 0.08);
    transition: transform 0.2s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .operator-card::before {
    content: "";
    position: absolute;
    top: -20px;
    left: -20px;
    width: 60px;
    height: 60px;
    background: rgba(66, 133, 244, 0.1);
    border-radius: 50%;
    z-index: 0;
  }
  
  .operator-card h3 {
    font-size: 1.25rem;
    color: #1a73e8;
    margin-bottom: 0.5rem;
    position: relative;
    z-index: 1;
  }
  
  .operator-card p {
    margin: 0.3rem 0;
    font-size: 0.95rem;
    color: #555;
    position: relative;
    z-index: 1;
  }
  
  .operator-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(66, 133, 244, 0.12);
  }
  
  .loading-text,
  .error-text,
  .no-operators {
    text-align: center;
    font-size: 1rem;
    margin-top: 2rem;
    color: #999;
  }
  
  .error-text {
    color: #e53935;
  }