/* Container */
.screening-container {
    max-width: 100%;
    margin: 5% auto;
    padding: 2rem;
    background-color: #ffffff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #2c3e50;
  }
  
  /* Heading */
  .screening-header {
    text-align: center;
    color: #4285f4;
    font-weight: bold;
    margin-bottom: 2.5rem;
  }
  
  /* List of questions */
  .screening-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Individual question block */
  .screening-item {
    padding: 1rem 0;
    border-bottom: 1px solid #eee;
  }
  
  /* Section name */
  .section-label {
    font-weight: 600;
    color: #4285f4;
    margin-bottom: 0.3rem;
    font-size: 1rem;
  }
  
  /* Question */
  .question-text {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  /* Options */
  .option-list {
    padding-left: 1.2rem;
    color: #555;
    margin-top: 0.3rem;
  }
  
  .option-list li {
    margin-bottom: 0.2rem;
  }
  
  /* States */
  .loading,
  .error {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 2rem;
    color: #999;
  }
  
  .error {
    color: red;
  }