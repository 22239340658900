body {
    margin: 0;
    /* font-family: 'Poppins', sans-serif; */
    background-color: #f9f9f9; /* Soft neutral background for a clean look */
    color: #444; /* Soft font color for readability */
}

.doctors-page {
    margin-top: 5%;
    padding: 20px;
    text-align: center;
    min-height: 100vh;
    box-sizing: border-box; 
    background-color: white;
}

.add-btn {
    display: inline-block;
    margin: 0 auto 20px auto;
    padding: 12px 25px;
    background-color: #4285F4; /* Matches the page theme */
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.add-btn:hover {
    background-color: #156af4; /* Slightly darker pink */
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(65, 122, 220, 0.2);
}

.form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.form-modal {
    background: white;
    padding: 25px;
    border-radius: 12px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    width: 90%; /* Adjust to be mobile-friendly */
    max-width: 400px;
    text-align: center;
}

.doctor-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
}

.doctor-card {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    width: 100%; /* Full width on smaller devices */
    max-width: 320px; /* Limits the size on larger devices */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.doctor-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.doctor-card h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #4285F4;
    font-weight: bold;
}

.doctor-card p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #555;
    line-height: 1.4;
}

.doctor-card .btn-danger {
    padding: 10px 20px;
    background-color: #1c6ff6;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.doctor-card .btn-danger:hover {
    background-color: #12171e;
    box-shadow: 0 5px 15px rgba(92, 160, 249, 0.2);
}

/* Global container and layout */
.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensures it spans the full viewport */
}

.content-wrap {
    flex: 1;
    padding: 20px; /* Add spacing if needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.footer {
    background-color: #4285F4;
    color: white;
    text-align: center;
    padding: 15px 20px;
    font-size: 0.9rem;
    margin-top: auto;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Responsive Design - Media Queries */

/* Smaller screens (tablets and phones) */
@media (max-width: 768px) {
    .page-title {
        font-size: 1.8rem;
        margin-bottom: 15px;
    }

    .add-btn {
        font-size: 0.9rem;
        padding: 10px 20px;
    }

    .doctor-card {
        width: 100%; /* Use full width */
        margin: 0 auto;
    }

    .footer {
        font-size: 0.8rem;
        padding: 10px;
    }
}

/* Extra small screens (phones in portrait mode) */
@media (max-width: 480px) {
    .page-title {
        font-size: 1.5rem;
    }

    .add-btn {
        padding: 8px 15px;
        font-size: 0.85rem;
    }

    .form-modal {
        width: 95%;
    }
}