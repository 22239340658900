/* ScreenTestForm.css */

.screening-container {
    max-width: 600px;
    margin: 5% auto;
    padding: 2rem;
    background-color: #fff;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    font-family: "Segoe UI", sans-serif;
    color: #2c3e50;
    animation: fadeInUp 0.6s ease-out;
  }
  
  .screening-container h2 {
    text-align: center;
    color: #4285f4;
    margin-bottom: 1.5rem;
  }
  
  .question-count {
    text-align: center;
    color: #777;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  
  .question-box h3 {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    color: #333;
    text-align: center;
  }
  
  .options {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .option-button {
    padding: 12px 20px;
    border-radius: 10px;
    border: 2px solid #ccc;
    background-color: #f9f9f9;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s;
    text-align: left;
  }
  
  .option-button:hover {
    border-color: #4285f4;
    background-color: #eaf1ff;
  }
  
  .option-button.selected {
    background-color: #4285f4;
    color: white;
    border-color: #4285f4;
    font-weight: bold;
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  
  .navigation-buttons button {
    background-color: #4285f4;
    color: white;
    padding: 10px 18px;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .navigation-buttons button:hover:not(:disabled) {
    background-color: #3367d6;
  }
  
  .navigation-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .report-box {
    padding: 2rem;
    border-radius: 12px;
    background-color: #f4f8fd;
    border: 1px solid #dbeafe;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    margin-top: 2rem;
  }
  
  .report-box h3 {
    text-align: center;
    color: #1d4ed8;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }
  
  .report-text {
    font-size: 1rem;
    color: #333;
    background-color: #ffffff;
    padding: 1.2rem;
    border-radius: 8px;
    border: 1px solid #cbd5e1;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    white-space: pre-line;
  }
  
  .report-actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .print-btn,
  .proceed-btn {
    background-color: #4285f4;
    color: white;
    border: none;
    padding: 10px 16px;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .print-btn:hover,
  .proceed-btn:hover {
    background-color: #3367d6;
  }
  
  .error {
    color: red;
    text-align: center;
    margin-top: 1rem;
    font-weight: 500;
  }
  
  .success {
    color: green;
    text-align: center;
    margin-top: 1rem;
    font-weight: 500;
  }
  
  .loading {
    text-align: center;
    font-size: 1.2rem;
    color: #666;
    margin-top: 5rem;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }