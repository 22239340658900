/* Form Overlay */

.form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
    padding: 20px; 
  }
  
  
  .form-modal {
    background: white;
    color: #333;
    padding: 30px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh; 
    border-radius: 12px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    overflow-y: auto; 
  }
  
  
  
  
  /* Title inside Modal */
  .form-modal h2 {
    font-size: 24px;
    color: #FF8096;
    font-weight: 700;
    margin-bottom: 15px;
  }
  
  /* Form Styling */
  .doctor-form {
    display: flex;
    flex-direction: column;
    gap: 10px; 
    max-height: 65vh;
    overflow-y: auto;
    padding-right: 10px; 
  }
  
  
  /* Inputs & Select Fields */
  .doctor-form input,
  .doctor-form select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    background: #f8f8f8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: 0.2s ease-in-out;
  }
  
  /* Input and select field hover effect */

   #name{
    margin: 4px;
  } 
  #name:hover{
    transform: translateY(2px);
    background:white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 10;
    font-size: 20px;
    color:#FF8096;
    border-color: #FF8096;
  }
  #name:hover::placeholder{
    color: #FF8096;
    transition: color 1s ;
  }
  
  /* Input Focus Effect */
  .doctor-form input:focus,
  .doctor-form select:focus {
    border-color: #FF8096;
    background: white;
    outline: none;
  }
  
  
  /* Button Group */
  .button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
  }
  
  
  /* Button Styling */
  .btn {
    padding: 12px 18px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }
  .auto-gen:hover{
    background-color: #FF8096;
    color: white;
  }
  
  .btn:hover {
    opacity: 0.8;
  }
  
  #enterotp{
    display: flex;
    /* flex-wrap: wrap; */
    align-items: center;
    justify-content: space-between;
    width: 160%;
    overflow: hidden;
  }

  .btn-primary {
    background: #FF8096;
    color: white;
    margin-top: 22px;
    margin-right: 25px;
  }
  .btn-primary:hover{
    background: rgb(32, 130, 32);
    transform: translateY(-2px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .btn-success {
    background: #FF8096;
    color: white;
    margin-top: 150px;
    padding-top: 5px;
    padding-bottom: 5px;
    position: relative;
    top: -78px;
  }
  
  .btn-success:hover{
    transform: translateY(-3px);
    background: #FF8096;
  
  }
  .btn-secondary {
    background: #6c757d;
    color: white;
    /* padding-top: 50px; */
    margin-top: 72px;
    margin-bottom: 80px;
    margin-right: 4px;
    position: relative;
  }
  .btn-secondary:hover{
    transform: translateY(-2px);
  }
  
  .btn-danger {
    background: #dc3545;
    color: white;
  }
  
  .add-btn:hover{
    background-color: white;
    color:#FF8096;
  }
  
  /* Animation for Modal */
  @keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
  }
  
  /* Mobile Responsive */
  @media (max-width: 600px) {
    .form-modal {
        width: 95%;
        padding: 20px;
        max-height: 85vh;
    }
  
    .doctor-form {
        max-height: 60vh;
    }
  
    .button-group {
        flex-direction: column;
        align-items: center;
    }
  }