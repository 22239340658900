/* RegisterPatient.css */

.register-container {
  max-width: 500px;
  margin: 5% auto;
  background-color: white;
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  font-family: "Segoe UI", sans-serif;
  color: #2c3e50;
  animation: fadeInUp 0.6s ease-out;
}

.register-container h2 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  color: #4285f4;
  animation: slideDown 0.5s ease-out;
}

.register-container p {
  text-align: center;
  margin-bottom: 2rem;
  color: #555;
  font-size: 1rem;
  animation: fadeIn 1s ease-out;
}

.register-container input,
.register-container select {
  width: 100%;
  padding: 12px;
  margin-bottom: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
  animation: fadeIn 0.6s ease-out;
}

.register-container input:focus,
.register-container select:focus {
  border-color: #4285f4;
  box-shadow: 0 0 8px rgba(66, 133, 244, 0.2);
  outline: none;
}

.register-container button {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s, transform 0.2s;
  animation: fadeInUp 0.6s ease-out;
}

.register-container button:hover {
  background-color: #3367d6;
  transform: translateY(-2px);
}

.register-container h3 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
  color: #4285f4;
  animation: slideDown 0.5s ease-out;
}

.register-container .error {
  color: red;
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;
  animation: fadeIn 0.6s ease-in;
}

.register-container .success {
  color: green;
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;
  animation: fadeIn 0.6s ease-in;
}

/* Step indicator */
.step-indicator {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  animation: fadeIn 0.5s ease-in-out;
}

.step-indicator .step {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #d1d5db;
  transition: background-color 0.3s;
}

.step-indicator .step.active {
  background-color: #4285f4;
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

.step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.step {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #d1d5db;
  transition: background-color 0.3s;
}

.step.active {
  background-color: #4285f4;
}

.step-label {
  font-size: 0.85rem;
  color: #555;
  font-weight: 500;
}

.step-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.progress-line {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e5e7eb;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  transition: background-color 0.3s;
}

.circle.active {
  background-color: #4285f4;
}

.line {
  height: 4px;
  width: 40px;
  background-color: #e5e7eb;
  transition: background-color 0.3s;
}

.line.filled {
  background-color: #4285f4;
}

.labels {
  display: flex;
  justify-content: space-between;
  width: 180px;
  margin-top: 8px;
  font-size: 0.85rem;
  color: #555;
  font-weight: 500;
}