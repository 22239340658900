/* FAQ Page Styles */
body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: white; /* Entire page background white */
    color: #333; /* Text color */
}

.faqContainer {
    max-width: auto;
    margin: 6% auto;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: white;
}

.faqTitle {
    text-align: center;
    font-weight: bold;
    color: #4285F4;
    margin-bottom: 20px;
}

.faqSection {
    margin-bottom: 30px;
}

.faqCategory {
    font-size: 1.8rem;
    color: #444;
    border-bottom: 2px solid #4285F4;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.faqQuestions {
    margin-top: 10px;
}

.faqItem {
    margin-bottom: 15px;
}

.faqQuestion {
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    color: #333;
    background: none;
    border: none;
    outline: none;
    padding: 10px;
    width: 100%;
    text-align: left;
    transition: all 0.3s ease;
}

.faqQuestion:hover {
    color: #82b2ff;
}

.faqAnswer {
    margin-top: 10px;
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
    padding: 0 10px;
}