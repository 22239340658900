/* About.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

.about-page {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #fff;
  margin-top: 5%;
}

/* Hero Section */
.hero-section {
  text-align: center;
  padding: 60px 20px;
  background-color: rgba(0, 0, 0, 0.3); /* Fallback color if image fails to load */
  background: url('./About.jpg') no-repeat center center; /* Center the image */
  background-size: cover; 
  position: relative;
  z-index: 1; /* Ensure it’s not overlapped */
  min-height: 300px; /* Optional: Set a minimum height for visibility */
  box-shadow: #666;
}

.hero-section h1 {
  font-size: 2.5rem;
  color: #fff; /* Blue text */
  margin-top: 40px;
  margin-bottom: 40px;
}

.hero-section p {
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
  color: #666; /* Gray text */
}

/* Mission Section */
.mission-section {
  display: flex;
  align-items: center;
  padding: 40px;
  gap: 30px;
  background-color: #fff;
}

.mission-content {
  flex: 1;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0);
  width: 100%;
  max-width: 800px; /* Limit the width of the white boxes */
  transition: transform 0.3s ease;
  margin-right: 50px;
}

.mission-content h2 {
  font-size: 2rem;
  color: #4285F4;
  margin-bottom: 10px;
}

.mission-content p {
  font-size: 1rem;
  line-height: 1.5;
}

.mission-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
  background-size: contain; 
  position: relative;
}

/* Services Section */
.services-section {
  padding: 40px;
  text-align: center;
  background-color: #fff;
}

.services-section h2 {
  font-size: 2rem;
  color: #4285F4;
  margin-bottom: 25px;
  
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 15px;
}

.service-card {
  background-color: rgba(234, 224, 224, 0.7);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(66, 133, 244, 0.2);
}

.service-card h3 {
  font-size: 1.3rem;
  color: #4285F4;
  margin-bottom: 10px;
}

.service-card p {
  font-size: 0.95rem;
  color: #666;
}

/* Founder's Note Section */
.founder-section {
  display: flex;
  align-items: center;
  padding: 40px;
  gap: 30px;
  background-color: #e8f0fe;
}

.founder-content {
  flex: 1;
}

.founder-content h2 {
  font-size: 2rem;
  color: #4285F4;
  margin-bottom: 15px;
}

.founder-content blockquote {
  font-size: 1.2rem;
  font-style: italic;
  color: #4285F4;
  margin: 15px 0;
  padding-left: 15px;
  border-left: 3px solid #3267d6;
}

.founder-content p {
  font-size: 1rem;
  line-height: 1.5;
}

.founder-signature {
  margin-top: 15px;
  font-weight: 700;
  color: #333;
}

.founder-signature span {
  font-weight: 400;
  font-size: 0.9rem;
  color: #666;
}

.founder-image {
  flex: 1;
  text-align: center;
}

.blue-circle {
  width: 250px;
  height: 250px;
  background-color: #4285F4;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  transition: all 0.3s ease;
}

.blue-circle:hover {
  transform: scale(1.05);
}

.founder-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Team Section */
.team-section {
  padding: 40px;
  text-align: center;
  background-color: #fff;
}

.team-section h2 {
  font-size: 2rem;
  color: #4285F4;
  margin-bottom: 25px;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 15px;
}

.team-member {
  transition: all 0.3s ease;
  background-color: rgba(234, 224, 224, 0.7);
}

.team-member:hover {
  transform: translateY(-5px);
}

.team-member img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 2px solid #4285F4;
}

.team-member h3 {
  font-size: 1.2rem;
  color: #4285F4;
  margin-bottom: 5px;
}

.team-member p {
  font-size: 0.9rem;
  color: #666;
}

/* Get in Touch Section */
.get-in-touch-section {
  padding: 40px;
  background-color: #f8f9fa;
  text-align: center;
}

.get-in-touch-section h2 {
  font-size: 2rem;
  color: #4285F4;
  margin-bottom: 25px;
}

.get-in-touch-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 15px;
  
}

.get-in-touch-item {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: rgba(234, 224, 224, 0.7);
}

.get-in-touch-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(66, 133, 244, 0.2);
}

.get-in-touch-item .icon {
  width: 40px;
  height: 40px;
  margin-bottom: 15px;
  position: relative;
}

.puzzle-icon::before,
.puzzle-icon::after,
.handshake-icon::before,
.handshake-icon::after,
.question-icon::before,
.question-icon::after {
  content: '';
  position: absolute;
  background-color: #4285F4;
  transition: all 0.3s ease;
}

.puzzle-icon::before { width: 25px; height: 25px; top: 0; left: 0; border-radius: 4px; }
.puzzle-icon::after { width: 25px; height: 25px; bottom: 0; right: 0; border-radius: 4px; }
.handshake-icon::before { width: 30px; height: 8px; top: 16px; left: 0; transform: rotate(45deg); border-radius: 4px; }
.handshake-icon::after { width: 30px; height: 8px; top: 16px; right: 0; transform: rotate(-45deg); border-radius: 4px; }
.question-icon::before { width: 25px; height: 25px; top: 0; left: 8px; border-radius: 50%; }
.question-icon::after { content: '?'; font-size: 16px; color: #fff; top: 4px; left: 15px; }

.get-in-touch-item:hover .icon::before,
.get-in-touch-item:hover .icon::after {
  background-color: #3267d6;
}

.get-in-touch-item h3 {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 10px;
}

.get-in-touch-link {
  font-size: 1rem;
  color: #4285F4;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease;
}

.get-in-touch-link:hover {
  color: #3267d6;
}

/* Testimonials Section */
.testimonials-section {
  padding: 40px;
  background-color: #fff;
  text-align: center;
}

.testimonials-section h2 {
  font-size: 2rem;
  color: #4285F4;
  margin-bottom: 25px;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 15px;
}

.testimonial {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: rgba(234, 224, 224, 0.7);
}

.testimonial:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(66, 133, 244, 0.2);
}

.testimonial p {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial h4 {
  font-size: 0.9rem;
  color: #4285F4;
}

/* Footer */
.footer {
  background-color: #4285F4;
  color: #fff;
  padding: 30px 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  flex-wrap: wrap;
  gap: 20px;
}

.footer-section h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.footer-section p {
  font-size: 0.9rem;
  line-height: 1.4;
}

.footer-bottom {
  text-align: center;
  padding: 15px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 15px;
}

/* Animations */
.slide-in {
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.slide-in.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-section, .mission-section, .services-section, 
  .founder-section, .team-section, .get-in-touch-section, 
  .testimonials-section {
    padding: 30px 15px;
  }

  .mission-section, .founder-section {
    flex-direction: column;
  }

  .blue-circle {
    width: 200px;
    height: 200px;
  }

  .footer-container {
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .hero-section h1, .mission-content h2, .services-section h2, 
  .founder-content h2, .team-section h2, .get-in-touch-section h2, 
  .testimonials-section h2 {
    font-size: 1.8rem;
  }

  .hero-section p, .mission-content p, .founder-content p {
    font-size: 0.95rem;
  }

  .blue-circle {
    width: 150px;
    height: 150px;
  }
}
@media (prefers-reduced-motion: reduce), (scripting: none) {
  .slide-in {
    opacity: 1;
    transform: none;
  }
}